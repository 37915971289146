import React, {useState} from 'react'
// import {SiDatabricks} from "react-icons/si"
import{AiFillSkin} from "react-icons/ai"
import {FaBars, FaTimes} from 'react-icons/fa'
import "./NavbarStyles.css"
import { Link } from 'react-router-dom'

const Navbar = () => {

  const [nav, setNav] = useState(false)
  const handleNav = () => setNav(!nav)

  return (
    <div className='navbar'>
        <div className='container'>
            <div className='logo'>
                {/* <SiDatabricks className='icon' /> */}
                <AiFillSkin className='icon' />
                <h1>Soutien de l'Ukraine</h1>
            </div>

            {/* <ul className='nav-menu'> */}
            {/* <ul className={nav ? 'nav-menu active' : 'nav-menu'} > */}
            <ul className={nav? 'nav-menu active' : 'nav-menu'} >
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/shirts'>T-Shirts</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
              <div>
                <a href='https://www.facebook.com/yuliya.samoylenko.12'>
                  <button>Facebook</button>
                </a>
              </div>
            </ul>
            <div className='hamburger' onClick={handleNav}>
              {!nav ? (<FaBars className='icon' />) : (<FaTimes className='icon' />) }
              {/* <FaBars className='icon'/> */}
            </div>

        </div>
        
    </div>
  )
}

export default Navbar