import React from 'react'
import Shirts from '../shirts/Shirts'

const ShirtsPage = () => {
  return (
    <>
        <Shirts />
    </>
  )
}

export default ShirtsPage