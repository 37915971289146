import React from 'react'
import './ShirtsStyles.css'
import { Link } from 'react-router-dom'

const Shirts = () => {
  return (
    <div className='shirts'>
        <div className='container'>
            <div className='content'>
                <h2><span>Shirts</span> to order</h2>
                <p>Наразі ми пропонуємо футболки за дізайном українського митця що мешкає та працює у Монреалі, Андрій Кульян.</p>
                <p>Actuellement, nous proposons des t-shirts avec le design de l'artiste ukrainien Andrii Kulian, qui vit et travaille à Montréal.</p>
                <div>
                <Link to='/contact'>
                  <button>Contact</button>
                </Link>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Shirts