import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Recovery from './routes/Recovery';
// import ContactPage from './routes/ContactPage';
import ContactPage from './components/routes/ContactPage';
import ShirtsPage from './components/routes/ShirtsPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      {/* <Route path='/recovery' element={<Recovery />} /> */}
      {/* <Route path='/contact' element={<ContactPage />} /> */}
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/shirts' element={<ShirtsPage />} />
    </Routes>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';

// import {BrowserRouter, Routes, Route} from 'react-router-dom'

// import Recovery from './routes/RecoveryPage'
// import CloudPage from './routes/CloudPage'
// import ContactPage from './routes/ContactPage'

// ReactDOM.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path='/' element={<App />} />
//       {/* <Route path='/recovery' element={<Recovery />} /> */}
//       {/* <Route path='/cloud' element={<CloudPage />} /> */}
//       {/* <Route path='/contact' element={<ContactPage />} /> */}
//     </Routes>
//   </BrowserRouter>,
//   document.getElementById('root')
// );

