// import React from 'react'
// import Navbar from '../navbar/Navbar'
// import Contact from '../components/contact/Contact'

// const ContactPage = () => {
//   return (
//     <>
//         <Navbar />
//         <Contact />
//     </>
//   )
// }

// export default ContactPage

import React from 'react'
import Contact from '../contact/Contact'
// import Footer from '../components/footer/Footer'
import Navbar from '../navbar/Navbar'

const ContactPage = () => {
    return (
        <>
            <Navbar/>
            <Contact />
            {/* <Footer />    */}
        </>
    )
}

export default ContactPage