import React from 'react'
import './HeroStyles.css'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='hero'>
      <div className='container'>
        <div className='content'>
            <h1>збираємо</h1>
            <h1 className='blue'>на</h1>
            <h1>дрони</h1>
            <div>
                <Link to='/shirts'>
                  <button>Continuer</button>
                </Link>
              </div>
        </div>
      </div>
    </div>
  )
}

export default Hero